import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import InsidePageBanner from '../Component/InsidePageBanner'
import heroline from "../assets/images/hero-line.png"
import faqimg from "../assets/images/faqside.jpg"

import { getapi } from '../Api/Api'
import { BaseURL } from '../Api/BaseUrl'
import Slider from 'react-slick'
import SEO from '../SeoComponent/Seo'

function About() {

    const [data, setdata] = useState([])
    const [team, setteam] = useState([])
    const [faq, setfaq] = useState([])
    const [aboutcarddata, setaboutcarddata] = useState([])


    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const handleget = async () => {
        let res = await getapi('about')
        setdata(res.data.data)
    }

    const handlefaq = async () => {
        let res = await getapi('faq')
        setfaq(res.data.data)
    }


    const handlegetteam = async () => {

        let res = await getapi('team')
        setteam(res.data.data)
    }

    const handleaboutteam = async () => {

        let res = await getapi('aboutcard')
        setaboutcarddata(res.data.data)
    }

    useEffect(() => {
        handleget()
        handlegetteam()
        handlefaq()
        handleaboutteam()
    }, [])


    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 4,
        speed: 500,
        dots: true,
        autoplay: true,
        autoplaySpeed: 1000,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex), // Track slide change
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: false
                }
            }
        ]
    };
    return (
        <>

            <SEO title="About - Haappeningads" description="we're committed to helping brands succeed in their industry with Haappeningads creative advertising" keywords="" />
            <Header />
            {/* <InsidePageBanner title="About" para="“A team of innovators and creatives who believe in doing things differently." /> */}
            <section className='aboutcards '>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Slider {...settings}>
                                {aboutcarddata.map((item, index) => (
                                    <div
                                        key={item.id}
                                        className={`slide mainsliderimg ${index === currentSlide ? "active-slide" : ""}`}

                                        style={{

                                            height: "400px" // Add padding to each slide for spacing
                                        }}
                                    >
                                        <img
                                            src={`${BaseURL}${item.image}`}
                                            style={{



                                                transform: index === currentSlide
                                                    ? "scale(1.05)"                  // Smaller scale effect for subtle highlight
                                                    : "scale(1)",
                                                transition: "transform 0.4s ease, box-shadow 0.4s ease", // Smooth transition
                                            }}
                                            className="img-fluid"
                                            alt={item.title}
                                        />
                                    </div>
                                ))}
                            </Slider>

                        </div>
                    </div>
                </div>
            </section>
            <section id="FAQ" style={{ marginTop: "60px" }}>
                <div className="container">

                    {data.map((item, index) => {
                        return (
                            <div className="row align-items-center mb-4" key={item._id}>
                                {index % 2 !== 0 ? (
                                    <>
                                        <div className="col-md-6">
                                            <img loading="lazy" src={`${BaseURL}${item.image}`} className='img-fluid rounded-3' alt="" />
                                        </div>
                                        <div className="col-md-6">
                                            <img loading="lazy" src={heroline} className='img-fluid' alt="" />
                                            <h3>{item.title}</h3>
                                            <p>{item.detail}</p>

                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="col-md-6">
                                            <img loading="lazy" src={heroline} className='img-fluid' alt="" />
                                            <h3>{item.title}</h3>
                                            <p>{item.detail}</p>

                                        </div>
                                        <div className="col-md-6">
                                            <img loading="lazy" src={`${BaseURL}${item.image}`} className='img-fluid rounded-3' alt="" />
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </section >

            {team.length > 0 && <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <h3>Meet Our Team Member</h3>
                            </div>
                        </div>
                        {team.map((item, index) => {
                            return (
                                <>
                                    <div className="col-md-3">
                                        <div class="single-team-member">
                                            <div class="image">
                                                <img loading="lazy" src={`${BaseURL}${item.image}`} class="img-fluid rounded-circle" alt="" />
                                            </div>
                                            <div class="team-content mt-3">
                                                <h3>{item.title}</h3>
                                                <h6 class="t-orange fw-bold">
                                                    {item.position}</h6>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </section>}

            {faq.length > 0 && <div class="testimonial-section pt-80 pb-80">
                <div class="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <img loading="lazy" src={heroline} className='img-fluid' alt="" />
                                <h2>FAQ</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center position-relative">

                        <div className="col-md-12">
                            {faq.map((item, index) => (
                                <div key={index} className="faq">
                                    <div className={`heading ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleAccordion(index)}>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h5 className='mb-0'>{item.question}</h5>
                                            <h6 className='mb-0'><span> {activeIndex === index ? <i class="fa-solid fa-minus"></i> : <i class="fa-solid fa-plus"></i>} </span></h6>
                                        </div>
                                    </div>
                                    <div className={`contents ${activeIndex === index ? 'active' : 'd-none'}`} >
                                        <p className='text-secondary'> {item.answer}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>}

            <Footer />
        </>
    )
}

export default About
